import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { CommonFunction } from "../_helpers/common-function";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CartService {
  constructor(
    private http: HttpClient,
    private commonFunction: CommonFunction
  ) {}

  readonly listCurrentItemsKey = "list_current_items_key";

  headers = {
    currency: "USD",
    language: this.commonFunction.getSelectedLangCode(),
    source_code: this.commonFunction.getDomainName() || "LT",
  };

  private cartItems = new BehaviorSubject([]);
  getCartItems = this.cartItems.asObservable();

  private cartNumber = new BehaviorSubject(0);
  getSelectedCart = this.cartNumber.asObservable();

  private cartId = new BehaviorSubject(0);
  getCartId = this.cartId.asObservable();

  private selectedPack = new BehaviorSubject(0);
  getSelectedPack = this.selectedPack.asObservable();

  private selectedPackForCheckout = new BehaviorSubject(0);
  getSelectedPackForCheckout = this.selectedPackForCheckout.asObservable();

  private selectedPackLoaderForCheckout = new BehaviorSubject(0);
  getSelectedPackLoaderForCheckout =
    this.selectedPackLoaderForCheckout.asObservable();

  private cartPrices = new BehaviorSubject([]);
  getCartPrice = this.cartPrices.asObservable();

  private cartDeletedItem = new BehaviorSubject(-1);
  getCartDeletedItem = this.cartDeletedItem.asObservable();

  private loader = new BehaviorSubject(false);
  getLoaderStatus = this.loader.asObservable();

  private paymentOptions = new BehaviorSubject({});
  getPaymentOptions = this.paymentOptions.asObservable();

  private reviceItenaryOfMemberDiscount = new BehaviorSubject({});
  getReviceItenary = this.reviceItenaryOfMemberDiscount.asObservable();

  private reviceItenaryFromMembershipWarning = new BehaviorSubject({});
  getReviceItenaryFromMembershipWarning =
    this.reviceItenaryFromMembershipWarning.asObservable();

  private checkMemberAvailiblity = new BehaviorSubject({});
  getCheckMemberAvailiblity = this.checkMemberAvailiblity.asObservable();

  private stopBookingRequest = new BehaviorSubject({});
  getBookingStopRequest = this.stopBookingRequest.asObservable();

  private couponCode = new BehaviorSubject({});
  getCouponCode = this.couponCode.asObservable();

  private tripluencerDetail = new BehaviorSubject({});
  getTripluencerDetail = this.tripluencerDetail.asObservable();

  private validCartItems = new BehaviorSubject([]);
  getValidCartItems = this.validCartItems.asObservable();

  private invalidMemberItem = new BehaviorSubject(false);
  getInvalidMemberItem = this.invalidMemberItem.asObservable();

  private primaryUser = new BehaviorSubject(-1);
  getPrimaryUser = this.primaryUser.asObservable();

  private checkState = new BehaviorSubject(false);
  getCheckState = this.checkState.asObservable();

  private passCheckState = new BehaviorSubject(false);
  getPassCheckState = this.passCheckState.asObservable();

  private primaryUserPassword = new BehaviorSubject("");
  getPrimaryUserPassword = this.primaryUserPassword.asObservable();

  private primaryUserDetails = new BehaviorSubject({});
  getPrimaryUserDetails = this.primaryUserDetails.asObservable();

  private prefillOtherTravelers = new BehaviorSubject([]);
  getPrefillOtherTravelers = this.prefillOtherTravelers.asObservable();

  private hotelMessage = new BehaviorSubject<{ [key: string]: string }>({});
  getHotelMessage = this.hotelMessage.asObservable();

  private hotelAdults = new BehaviorSubject<any>({});
  public getHotelAdults: Observable<any> = this.hotelAdults.asObservable();

  setHotelAdults(hotelAdults) {
    this.hotelAdults.next(hotelAdults);
  }

  getHotelAdultsVal(): any {
    return this.hotelAdults.getValue();
  }

  // Update a specific value
  updateHotelAdults(group: number, key: number, value: boolean): void {
    const currentData = this.getHotelAdultsVal();
    if (!currentData[group]) {
      currentData[group] = {};
    }
    currentData[group][key] = value;
    this.hotelAdults.next({ ...currentData });
  }

  private cartTravelers = new BehaviorSubject({
    type0: {
      adults: [],
    },
    type1: {
      adults: [],
    },
    type2: {
      adults: [],
    },
    type3: {
      adults: [],
    },
    type4: {
      adults: [],
    },
    type5: {
      adults: [],
    },
    type6: {
      adults: [],
    },
    type7: {
      adults: [],
    },
    type8: {
      adults: [],
    },
    type9: {
      adults: [],
    },
  });
  getCartTravelers = this.cartTravelers.asObservable();

  updateHotelMessage(newData: { [key: string]: string }) {
    const currentData = this.hotelMessage.value;
    const updatedData = { ...currentData, ...newData };
    this.hotelMessage.next(updatedData);
  }

  removeHotelMessage(key: string) {
    const currentData = this.hotelMessage.value;
    const { [key]: _, ...updatedData } = currentData;
    this.hotelMessage.next(updatedData);
  }

  setHotelMessage(hotelMessage) {
    this.hotelMessage.next(hotelMessage);
  }

  setPrimaryUserPassword(primaryUserPassword) {
    this.primaryUserPassword.next(primaryUserPassword);
  }

  setPrimaryUserDetails(primaryUserDetails) {
    this.primaryUserDetails.next(primaryUserDetails);
  }

  setPrefillOtherTravelers(prefillOtherTravelers) {
    this.prefillOtherTravelers.next(prefillOtherTravelers);
  }

  setPassCheckState(passCheckState) {
    this.passCheckState.next(passCheckState);
  }

  setCheckState(checkState) {
    this.checkState.next(checkState);
  }

  setPrimaryUser(primaryUser) {
    this.primaryUser.next(primaryUser);
  }

  setCartItems(cartItem) {
    this.cartItems.next(cartItem);
  }

  setCartNumber(cartNumber) {
    this.cartNumber.next(cartNumber);
  }

  setCardId(cartId) {
    this.cartId.next(cartId);
  }

  setSelectedPack(selectedPack) {
    this.selectedPack.next(selectedPack);
  }

  setSelectedPackForCheckout(selectedPackForCheckout) {
    this.selectedPackForCheckout.next(selectedPackForCheckout);
  }

  setSelectedPackLoaderForCheckout(selectedPackLoaderForCheckout) {
    this.selectedPackLoaderForCheckout.next(selectedPackLoaderForCheckout);
  }

  setCartPrices(cartPrices) {
    this.cartPrices.next(cartPrices);
  }

  setDeletedCartItem(cartDeletedItem) {
    this.cartDeletedItem.next(cartDeletedItem);
  }

  setLoaderStatus(loader) {
    this.loader.next(loader);
  }

  setPaymentOptions(paymentOptions) {
    localStorage.setItem("__pm_inf", btoa(JSON.stringify(paymentOptions)));
    this.paymentOptions.next(paymentOptions);
  }

  setReviceItenary(data) {
    this.reviceItenaryOfMemberDiscount.next(data);
  }

  setMembershipreviceItenary(data) {
    this.reviceItenaryFromMembershipWarning.next(data);
  }

  setMemberCheckAvailiblity(data) {
    this.checkMemberAvailiblity.next(data);
  }

  setBookingRequest(data) {
    this.stopBookingRequest.next(data);
  }

  setCouponCode(data) {
    this.couponCode.next(data);
  }

  setTripluencerDetail(data) {
    this.tripluencerDetail.next(data);
  }

  setValidCartItems(validCartItems) {
    this.validCartItems.next(validCartItems);
  }

  setInvalidMemberItem(invalidMemberItem) {
    this.invalidMemberItem.next(invalidMemberItem);
  }

  setCartTravelers(travelers) {
    this.cartTravelers.next(travelers);
  }

  // Get Cart List Items
  getCartList(live_availiblity = "no", cart = null, coupon_code = "") {
    let data = {
      live_availiblity: live_availiblity,
      carts: [""],
      coupon_code: "",
    };
    if (cart == null) {
      data.carts = [""];
    } else {
      data.carts = cart;
    }
    if (coupon_code != "") {
      data.coupon_code = coupon_code;
    }
    let headers = {
      currency: "USD",
      language: "en",
      source_code: this.commonFunction.getDomainName() || "LT",
    };
    return this.http
      .post(
        `${environment.apiUrl}v1/cart/list?`,
        data,
        this.commonFunction.setHeaders(headers, false)
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Get Cross Cart List Items
  getCrossItemsCartList() {
    return this.http
      .post(
        `${environment.apiUrl}v1/cart/cross-list-items`,
        {},
        this.commonFunction.setHeaders(this.headers)
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Get Cross Cart List Items
  addCustomerRemark(data) {
    return this.http
      .post(
        `${environment.apiUrl}v1/cart/add-customer-remark`,
        data,
        this.commonFunction.setHeaders(this.headers)
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Add Item Into Cart
  addCartItem(data) {
    return this.http
      .post(
        `${environment.apiUrl}v1/cart/add`,
        data,
        this.commonFunction.setHeaders(this.headers)
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Add Arajet Package Item Into Cart
  addArPackage(data) {
    return this.http
      .post(
        `${environment.apiUrl}v1/travel-package/ar/add-cart`,
        data,
        this.commonFunction.setHeaders(this.headers)
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  addArFlight(data) {
    return this.http
      .post(
        `${environment.apiUrl}v1/cart/add-external-flight`,
        data,
        this.commonFunction.setHeaders(this.headers)
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Delete Item From Cart
  deleteCartItem(id) {
    return this.http.delete(
      `${environment.apiUrl}v1/cart/delete/${id}`,
      this.commonFunction.setHeaders()
    );
  }

  // Turn off the popup for instalment converted to full payment before 30 days
  closeTypePopup(id) {
    return this.http.patch(
      `${environment.apiUrl}v1/cart/close-instalment-modal`,
      { id: id },
      this.commonFunction.setHeaders()
    );
  }

  // Apply Tripfluencer Coupon Code
  applyCouponCode(data) {
    return this.http.post(
      `${environment.apiUrl}v1/cart/apply-coupon-code`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Update Item From Cart
  updateCart(data) {
    return this.http.put(
      `${environment.apiUrl}v1/cart/update`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Book The Cart Items
  bookCart(data) {
    return this.http.post(
      `${environment.apiUrl}v1/cart/book`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Get Booking Details
  getBookingDetails(bookingId) {
    return this.http.get(
      `${environment.apiUrl}v1/booking/cart-detail/${bookingId}`,
      this.commonFunction.setHeaders(this.headers)
    );
  }

  // Validate Payment For Booking
  validate(payload) {
    return this.http
      .post(
        `${environment.apiUrl}v1/payment/validate`,
        payload,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Validate Payment For Booking
  validateAuthUser(userData, cardData) {
    let data = { user_data: userData, card_data: cardData };
    return this.http
      .post(
        `${environment.apiUrl}v1/cart/guest-user-check`,
        data,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  validateGuestBooking(cardData, cartData) {
    return this.http
      .post(
        `${environment.apiUrl}v1/cart/guest-booking`,
        { savecard_data: cardData, cartbook_data: cartData },
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Verify Authorised User
  verifyAuth(transactionToken) {
    return this.http
      .get(
        `${environment.apiUrl}v1/payment/verify-auth/${transactionToken}`,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Check If Booking Is Suspended
  suspendBooking(payload) {
    return this.http
      .post(
        `${environment.apiUrl}v1/cart/suspend-booking`,
        payload,
        this.commonFunction.setHeaders()
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Delete Items
  deleteConflictedCartItem(ids) {
    const accessToken = localStorage.getItem("_lay_sess");
    const options = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        language: this.commonFunction.getSelectedLangCode(),
        source_code: this.commonFunction.getDomainName() || "LT",
      },
      body: {
        id: ids,
      },
    };

    return this.http
      .delete(
        `${environment.apiUrl}v1/cart/delete-conflicted-cart-item`,
        options
      )
      .pipe(catchError(this.commonFunction.handleError));
  }

  // Select Package Membership
  addSelectedPackage(data) {
    return this.http.put(
      `${environment.apiUrl}v1/cart/select-membership`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Add Membership Into Cart
  addMembershipIntoCart(data) {
    return this.http.post(
      `${environment.apiUrl}v1/cart/add-membership`,
      data,
      this.commonFunction.setHeaders()
    );
  }

  // Store Price To Local Storage
  storePriceChangePopupDetails(details) {
    localStorage.setItem(this.listCurrentItemsKey, details);
  }

  // Reset Stored Price To Local Storage
  resetPriceChangePopupDetails() {
    localStorage.removeItem(this.listCurrentItemsKey);
  }
}
