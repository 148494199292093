import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SeoService {
  constructor(private title: Title, private _meta: Meta) {}

  updateMetas(metas: any) {
    if (metas && metas?.meta) {
      let metaData = Object.entries(metas.meta).map(([key, value]) => ({
        key,
        value,
      }));

      if (metas?.social) {
        let socialData = Object.entries(metas.social).map(([key, value]) => ({
          key,
          value,
        }));

        socialData.forEach((m: any) => {
          this._meta.addTag({
            property: m.key,
            content:
              environment.code == "PG"
                ? m.value.replace("Laytrip", "Pagovoy")
                : m.value,
            key: m.key,
          });
        });
      }

      metaData.forEach((m: any) => {
        if (m.key == "title")
          this.title.setTitle(
            environment.code == "PG"
              ? m.value.replace("Laytrip", "Pagovoy")
              : m.value
          );
        this._meta.addTag({
          name: m.key,
          content:
            environment.code == "PG"
              ? m.value.replace("Laytrip", "Pagovoy")
              : m.value,
        });
      });
    }
    if (metas && metas?.og) {
      const ogData = Object.entries(metas.og).map(([key, value]) => ({
        key,
        value,
      }));

      ogData.forEach((m: any) => {
        this._meta.addTag({
          property: m.key,
          content:
            environment.code == "PG"
              ? m.value.replace("Laytrip", "Pagovoy")
              : m.value,
          key: m.key,
        });
      });
    }
  }
}
