import { Location } from "@angular/common";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { getLoginUserInfo } from "./jwt.helper";
import { ActivatedRoute, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class CommonFunction {
  langCode = environment.code == "PG" ? "es" : "en";
  code = sessionStorage.getItem("tripCode")
    ? "/" + sessionStorage.getItem("tripCode") + "/"
    : "/";
  constructor(
    private _location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    // Author: xavier | 2021/8/27
    // Description: Set locale for date & time functions
    const lang = localStorage.getItem("_lang");
    if (lang != null) {
      let language = JSON.parse(lang);
      moment.locale(JSON.parse(lang).iso_1Code);
      this.langCode = language.iso_1Code;
    }
  }

  getSelectedLangCode() {
    const lang = localStorage.getItem("_lang");
    if (lang != null) {
      let language = JSON.parse(lang);
      return language.iso_1Code;
    } else {
      return environment.code == "PG" ? "es" : "en";
    }
  }

  setHeaders(params = null, isFake = true) {
    let reqData: any = { headers: {} };
    const accessToken = isFake
      ? localStorage.getItem("_lay_sess_fake") ||
        localStorage.getItem("_lay_sess")
      : localStorage.getItem("_lay_sess");
    
    if (accessToken) {
      reqData = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          referral_id: ``,
          currency: "USD",
          language: this.getSelectedLangCode(),
          source_code: this.getDomainName() || "LT",
        },
      };
    } else {
      reqData = {
        headers: {
          currency: "USD",
          language: this.getSelectedLangCode(),
          source_code: this.getDomainName() || "LT",
        },
      };
    }
    if (params) {
      Object.keys(params).forEach((k) => {
        reqData.headers[k] = params[k];
      });
      reqData.headers.referral_id = ``;
    }

    // reqData.headers.origin = environment.name || "laytrip";
    return reqData;
  }

  setMembershipHeaders(params = "") {
    const accessToken = localStorage.getItem("_lay_sess");
    const reqData = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        referral_id: "",
        language: this.getSelectedLangCode(),
        source_code: this.getDomainName() || "LT",
      },
    };
    if (params) {
      let reqParams = {};
      Object.keys(params).forEach((k) => {
        reqParams[k] = params[k];
      });
      reqData["params"] = reqParams;
    }
    return reqData;
  }

  setWithoutLoginHeader() {
    return {
      headers: {
        language: this.getSelectedLangCode(),
        // origin: environment.name || "laytrip",
        source_code: this.getDomainName() || "LT",
      },
    };
  }

  convertDateFormat(date, sourceFormat, languageCode = null) {
    if (languageCode == null) {
      return moment(date, sourceFormat).format("MMM DD, YYYY");
    }
    return date;
  }

  convertDateYYYYMMDD(date, sourceFormat) {
    return moment(date, sourceFormat).format("YYYY-MM-DD");
  }
  convertDateMMDDYYYY(date, sourceFormat) {
    return moment(date, sourceFormat).format("MM/DD/YYYY");
  }

  convertDateMMYY(date, sourceFormat) {
    return moment(date, sourceFormat).format("MM/YY");
  }

  decodeUrl(url) {
    let prevUrl = [];
    let queryParams = {};
    if (url) {
      prevUrl = url.split("?");
      if (typeof prevUrl[1] != "undefined") {
        let params = prevUrl[1].split("&");
        for (let i in params) {
          let param = params[i].split("=");
          queryParams[param[0]] = param[1];
        }
      }

      return {
        url: prevUrl[0],
        params: queryParams,
      };
    }
    return {
      url: "/",
      params: {},
    };
  }

  getUserCountry() {
    try {
      let countryCode = localStorage.getItem("__uorigin");
      return countryCode || "";
    } catch (e) {
      return "";
    }
  }

  goBack() {
    if (window.history.length > 1) {
      this._location.back();
    } else {
      this.router.navigate([this.code]);
    }
  }

  getGuestUser() {
    let userDetails = getLoginUserInfo();
    if (!userDetails.roleId || userDetails.roleId == 7) {
      let guestuserId = localStorage.getItem("__gst");
      if (guestuserId) {
        return guestuserId;
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  convertCustomDateFormat(date, sourceFormat, destFormat, languageCode = null) {
    if (languageCode == null) {
      return moment(date, sourceFormat).format(destFormat);
    }
    return date;
  }

  preventNumberInput(event: any) {
    var a = [];
    var k = event.which;

    for (let i = 48; i < 58; i++) a.push(i);

    if (a.indexOf(k) >= 0) event.preventDefault();
  }

  getIpAddress2() {
    return this.http.get("https://api.country.is/");
  }

  toTitleCase(str) {
    if (str != null) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    } else {
      return "N/A";
    }
  }

  getDomainName() {
    return environment.code || "LT";
  }

  handleError(error) {
    let errorMessage = {};

    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = { message: error.error.message };
    } else {
      // server-side error
      errorMessage = { status: error.status, message: error.error.message };
    }
    return throwError(errorMessage);
  }

  handleGTMEvents(input) {
    let eventOb = {
      event: input.event || null,
      type: input.type || null,
      count: input.count || null,
      lang: localStorage.getItem("_lang")
        ? JSON.parse(localStorage.getItem("_lang")).name
        : null,
      origin: input.origin || null,
      destination: input.destination || null,
      location: input.location || null,
      title: input.title || null,
      email: input.email || null,
      ecommerce: input.ecommerce || null,
    };

    window["dataLayer"].push(eventOb);
  }

  parseIntItem(num) {
    if (num > 0) {
      return Math.floor(num);
    }
    return 0;
  }

  parseIntItemPlusOne(num) {
    if (num > 0) {
      return Math.ceil(num);
    }
    return 0;
  }

  checkRateInteger(num) {
    if (num > 0) {
      return !Number.isInteger(num);
    }
    return 0;
  }
  

  getSearchCombination(combinations) {
    return combinations.map((combination) => ({
      rooms: 1,
      adults: combination.a,
      children: combination.c.length,
      children_age: combination.c.length > 0 ? combination.c : undefined,
    }));
  }

  formatChildAdults = (rooms) => {
    return rooms.map((booking) => ({
      a: booking.adults,
      c: booking.children,
    }));
  };

  formatChildAdultsReverse = (rooms) => {
    return rooms.map((booking) => ({
      adults: booking.a,
      children: booking.c,
    }));
  };

  getParamOfUniqueRoom(room) {
    let regex = /param_(\d+)/;
    let match = room?.match(regex);
    return match?.[1] ? Number(match[1]) : null;
  }

  checktypeof(item) {
    return typeof item;
  }

  contiueToCart() {
    this.router.navigate([this.code + "cart/checkout"]);
  }

  sortByRelevancy(data, key, way) {
    if (typeof data === "undefined") {
      return data;
    } else {
      return data.sort(function (a, b) {
        var x = a.exclusiveDeal;
        var y = b.exclusiveDeal;
        if (x === y) {
          var xr = a?.hotelReviews?.[0]?.rate;
          var xy = b?.hotelReviews?.[0]?.rate;
          if (xr == xy) {
            var xp = a.selling["total"];
            var yp = b.selling["total"];
            if (way === "ASC") {
              return yp - xp || x - y; // high to low cost, then high to low exclusiveDeal
            } else {
              return xp - yp || y - x; // low to high cost, then low to high exclusiveDeal
            }
          } else {
            if (way === "ASC") {
              return xr - xy; // low to high rating
            } else {
              return xy - xr; // high to low rating
            }
          }
        } else {
          if (way === "ASC") {
            return y - x; // high to low exclusiveDeal
          } else {
            return x - y; // low to high exclusiveDeal
          }
        }
      });
    }
  }

  sortPriceJSON(data, key, way) {
    if (typeof data === "undefined") {
      return data;
    } else {
      return data.sort(function (a, b) {
        var x = a.selling[key];
        var y = b.selling[key];
        if (way === "ASC") {
          return x < y ? -1 : x > y ? 1 : 0;
        } else if (way === "DESC") {
          return x > y ? -1 : x < y ? 1 : 0;
        }
      });
    }
  }

  sortByRatings(data, key, way) {
    if (typeof data === "undefined") {
      return data;
    } else {
      return data.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        if (way === "ASC") {
          return x < y ? -1 : x > y ? 1 : 0;
        }
        if (way === "DESC") {
          return x > y ? -1 : x < y ? 1 : 0;
        }
      });
    }
  }

  sortByHotelName(data, key, way) {
    if (typeof data === "undefined") {
      return data;
    } else {
      return data.sort(function (a, b) {
        var x = a[key].toLowerCase();
        var y = b[key].toLowerCase();
        if (way === "ASC") {
          return x < y ? -1 : x > y ? 1 : 0;
        }
        if (way === "DESC") {
          return x > y ? -1 : x < y ? 1 : 0;
        }
      });
    }
  }

  sortByMembershipPrice(data, key, way) {
    if (typeof data === "undefined") {
      return data;
    } else {
      return data.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        if (way === "ASC") {
          return x < y ? -1 : x > y ? 1 : 0;
        }
        if (way === "DESC") {
          return x > y ? -1 : x < y ? 1 : 0;
        }
      });
    }
  }

  sortByMembershipPerc(data, key, way) {
    if (typeof data === "undefined") {
      return data;
    } else {
      return data.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        if (way === "ASC") {
          return x < y ? -1 : x > y ? 1 : 0;
        }
        if (way === "DESC") {
          return x > y ? -1 : x < y ? 1 : 0;
        }
      });
    }
  }

  formatLongText(data: any) {
    const maxLength = 150;
    const text: string = data == null ? "" : data; // null-coalescing operator not yet supported
    const tokens: string[] = text.split(".");
    let result: string = "";
    let offset: number = 0;
    for (let i: number = 0; i < tokens.length; i++) {
      if (result.length - offset >= maxLength) {
        result += "<br><br>";
        offset = result.length;
      }
      if (tokens[i].length > 0) result += `${tokens[i]}.`;
    }

    const fix = /\d+.\s+\d+/g;
    const matches = result.match(fix);

    if (matches) {
      matches.forEach((match) => {
        result = result.replace(match, match.replace(/\s+/g, ""));
      });
    }

    return result;
  }

  handleAmenitiesExchange(data, isCharged) {
    const specialAmenities = [
      {
        keyword: "air condition",
        name: "Air Conditioned",
        icon: "assets/air_conditioned.svg",
      },
      { keyword: "parking", name: "Parking", icon: "assets/parking.svg" },
      { keyword: "spa", name: "Spa", icon: "assets/spa.svg" },
      { keyword: "gym", name: "Gym", icon: "assets/gym.svg" },
      { keyword: "pool", name: "Swimming pool", icon: "assets/swimming.svg" },
      {
        keyword: "swimming",
        name: "Swimming pool",
        icon: "assets/swimming.svg",
      },
      {
        keyword: "restaurant",
        name: "Restaurant",
        icon: "assets/restaurant.svg",
      },
    ];

    return data.map((item) => {
      const lowerItem = item.toLowerCase();
      const specialAmenity = specialAmenities.find((a) =>
        lowerItem.includes(a.keyword)
      );

      if (specialAmenity) {
        return {
          name: specialAmenity.name,
          icon: specialAmenity.icon,
          value: "special",
          charged: isCharged,
        };
      } else {
        return {
          name: item,
          icon: "/assets/generic-service-black.svg",
          value: "common",
          charged: isCharged,
        };
      }
    });
  }
}
