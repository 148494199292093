import { Component, Inject } from "@angular/core";
import { GenericService } from "./shared/services/generic.service";
import { v4 as uuidv4 } from "uuid";
import { getLoginUserInfo } from "./shared/_helpers/jwt.helper";
import { UserService } from "./shared/services/user.service";
import { CheckOutService } from "./shared/services/checkout.service";
import { NavigationEnd, Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { PromoCodeService } from "./shared/services/promo.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HomeService } from "./shared/services/home.service";
import { SeoService } from "./shared/services/seo.service";
import { CookieService } from "ngx-cookie";
import { environment } from "src/environments/environment";
import { CartService } from "./shared/services/cart.service";
import { DOCUMENT } from "@angular/common";
import { Meta } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  original = window.location.pathname;
  constructor(
    private genericService: GenericService,
    private checkOutService: CheckOutService,
    private userService: UserService,
    private httpClient: HttpClient,
    private router: Router,
    private promoService: PromoCodeService,
    private homeService: HomeService,
    private modalService: NgbModal,
    private seoService: SeoService,
    private cookieService: CookieService,
    private cartService: CartService,
    @Inject(DOCUMENT) private doc,
    private _meta: Meta,
    private location: Location
  ) {}
  private combinedSubscription: Subscription;

  ngOnInit() {
    this.homeService.setUserIpDetails(true);
    if (this.original != "/sson" && this.original != "/all-packages") {
      this.registerGuestUser();
    }
    if (this.original != "/all-packages") {
      this.setCountryBehaviour();

      if (this.original != "/not-found") {
        if (this.original.split("/")[1]) {
          let routeTrip = [
            "hotel",
            "flight",
            "cart",
            "account",
            "cart",
            "book",
            "unsubscribe",
            "privacy-policy",
            "faqs",
            "sson",
            "about-us",
            "membership-program",
            "mpsch",
            "terms-and-conditions",
            "tripfluencer-tou",
            "travel-deal",
            "travel-deals",
            "pages",
            "all-packages",
          ];
          if (!routeTrip.includes(this.original.split("/")[1])) {
            this.checkCodeExist(this.original.split("/")[1]);
          }
        } else {
          sessionStorage.removeItem("tripCode");
        }
      } else {
        this.router.navigate(["not-found"]);
      }

      if (
        /Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        localStorage.setItem("device", "android");
      } else if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        localStorage.setItem("device", "ios");
      } else {
        localStorage.setItem("device", "web");
      }
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentPath = this.location.path();
        if (currentPath !== "/" && environment.code == "PG") {
          this.router.navigate(["/"]);
        }

        // close all open modals
        this.modalService.dismissAll();
        /* Update Canonical Url */

        /* Update Canonical Url */

        const head = this.doc.getElementsByTagName("head")[0];
        let element: HTMLLinkElement =
          this.doc.querySelector(`link[rel='canonical']`) || null;

        if (element == null) {
          element = this.doc.createElement("link") as HTMLLinkElement;
          head.appendChild(element);
        }

        element.setAttribute("href", this.doc.URL.split("?")[0]);
        element.setAttribute("rel", "canonical");
        element.setAttribute("key", "canonical");

        this._meta.addTag({
          property: "og:url",
          content: this.doc.URL.split("?")[0],
          key: "og:url",
        });

        this._meta.addTag({
          property: "twitter:url",
          content: this.doc.URL.split("?")[0],
          key: "twitter:url",
        });
      }
    });

    if (this.original != "/all-packages" && environment.code == "LT") {
      this.router.events.subscribe(async (event) => {
        if (event instanceof NavigationEnd) {
          let pageType = "home";
          if (event.url != "/") {
            pageType = event.url.replace(/^\/|\/$/g, "");
          }
          // Get SEO Meta data from DB
          await this.genericService
            .getMetaTags(pageType.toString())
            .subscribe((response: any) => {
              if (response && response.data) {
                this.seoService.updateMetas(response.data);
              }
            });
        }
      });
    }

    this.homeService.getUserIpDetails.subscribe((res: any) => {
      if (res) {
        this.getIpAddress();

        this.homeService.setUserIpDetails(false);
      }
    });

    if (environment.code == "PG") {
      this.removeCache();
    }

    if (this.cookieService.get("__cke") && this.original != "/all-packages") {
      let __cke = JSON.parse(this.cookieService.get("__cke"));

      if (__cke == true) {
        this.removeCache();
      } else if (
        __cke != "true" &&
        __cke.value &&
        __cke.value != environment.version
      ) {
        this.removeCache();
      }
    }
  }

  async getIpAddress() {
    if (this.original != "/all-packages") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (success) => {
            this.getUserLocation();
          },
          (failure) => {
            this.getUserLocation();
          }
        );
      } else {
        localStorage.setItem("ip_address", "null");
        this.setIpJSON(null);
      }
    }
  }

  getUserLocation() {
    this.homeService.getIpInfo().subscribe((res: any) => {
      if (res && res.ip) {
        this.combinedSubscription = this.homeService
          .getGeoInfo(res.ip)
          .subscribe((data: any) => {
            localStorage.setItem("ip_address", data.geoplugin_request);
            let ipDetails = {
              IPv4: data.geoplugin_request,
              city: data.geoplugin_city,
              country_name: data.geoplugin_countryName,
              country_code: data.geoplugin_countryCode,
              postal: null,
              state: data.geoplugin_regionName,
              latitude: data.geoplugin_latitude,
              longitude: data.geoplugin_longitude,
            };
            this.setIpJSON(ipDetails);
          });
      }
    });
  }

  setIpJSON(res) {
    localStorage.setItem(
      "lt_location",
      JSON.stringify({
        ip: res.IPv4 || null,
        city: res.city || null,
        country: res.country_name || null,
        countryCode: res.country_code || null,
        postal: res.postal || null,
        state: res.state || null,
        lat: res.latitude || null,
        long: res.longitude || null,
      })
    );
    this.homeService.setFromLocation(true);
    this.homeService.setLoadingDeal(true);
  }

  removeCache() {
    this.cookieService.remove("__cke");
    if (!window.location.href.includes("cart")) {
      localStorage.removeItem("_lay_sess");
      localStorage.removeItem("$crt");
      localStorage.removeItem("$cartOver");
      localStorage.removeItem("lt_location");
      this.cookieService.remove("__cc");
      sessionStorage.removeItem("login_details");
      localStorage.removeItem("daily_attempt_count");
      this.homeService.setCheckLoginUser(true);
      this.cartService.setCartItems([]);
      this.cartService.resetPriceChangePopupDetails();
      this.loginGuestUser();
    }
  }

  loginGuestUser() {
    let uuid = uuidv4();
    localStorage.setItem("__gst", uuid);
    this.userService
      .registerGuestUser({ guest_id: uuid })
      .subscribe((result: any) => {
        localStorage.setItem("_lay_sess", result.accessToken);
        // window.location.href = "/";
      });
  }

  checkCodeExist(value) {
    if (value == "") {
      sessionStorage.removeItem("tripCode");
      this.router.navigate(["not-found"]);
    }
    let data = {
      coupon_code: value,
    };

    this.promoService.codeVerify(data).subscribe((d: any) => {
      if (d && !d.availableStatus) {
        this.genericService.setTripfluencer({ tripfluencer: value });
        sessionStorage.setItem("tripCode", value);
      } else {
        sessionStorage.removeItem("tripCode");
        this.router.navigate(["not-found"]);
      }
    });
  }

  registerGuestUser() {
    let user = getLoginUserInfo();

    if (!user.roleId || user.roleId == 7 || environment.code == "PG") {
      let guestId = localStorage.getItem("__gst");

      if (!guestId || environment.code == "PG") {
        guestId = uuidv4();
        localStorage.setItem("__gst", guestId);
      }

      this.userService
        .registerGuestUser({ guest_id: guestId })
        .subscribe((result: any) => {
          localStorage.setItem("_lay_sess", result.accessToken);
          this.homeService.setCheckLoginUser(true);
        });
    }
  }

  setCountryBehaviour() {
    this.genericService.getCountry().subscribe((res) => {
      this.checkOutService.setCountries(res);
    });
  }

  ngOnDestroy() {
    if (this.combinedSubscription) {
      this.combinedSubscription.unsubscribe();
    }
  }
}
